import React from 'react';
import styled from '@emotion/styled';
import { CategoryModel } from '@nimles/models';
import { Row, Column, Card, CardImage } from '@nimles/react-web-components';
import { getImageUrl } from '../../../utils';
import { Link } from 'gatsby-plugin-react-i18next';

const ImageText = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  transition: all 200ms;
  text-shadow: 0 0 2px rgba(0, 0, 0, 1);

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

interface Props {
  categories: CategoryModel[];
  css?: any;
}

export const CategoryGrid = ({ categories, css, ...props }: Props) => {
  return (
    <Row wrap="wrap">
      {categories
        ?.map((category) => ({
          ...category,
          popularity: category.description?.length || 0,
        }))
        .sort((a, b) => (b.name < a.name ? 1 : b.name > a.name ? -1 : 0))
        .map(({ name, uniqueName, bannerId }) => (
          <Column
            key={uniqueName}
            xs={50}
            sm={33.333}
            md={25}
            lg={20}
            xl={16.66}
            xxl={16.66}
          >
            <Link to={`/${uniqueName}-c`}>
              <Card>
                <CardImage
                  src={getImageUrl(bannerId, 480)}
                  alt={name}
                  ratio={1}
                  fit="cover"
                >
                  <ImageText>{name}</ImageText>
                </CardImage>
              </Card>
            </Link>
          </Column>
        ))}
    </Row>
  );
};
