import React from 'react';
import styled from '@emotion/styled';

const QuantityRow = styled.div<{ size: number }>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.input.borderColor};
  border-radius: ${({ theme, size }) => theme.input.borderRadius * size}px;
  line-height: normal;
`;

const QuantityButton = styled.button<{ size: number }>`
  border: none;
  padding: ${({ theme, size }) =>
    `${theme.button.paddingVertical * size}px ${
      theme.button.paddingHorizontal * size
    }px`};
  font-size: ${({ theme }) => theme.button.fontSize};
`;

const QuantityInput = styled.input<{ size: number }>`
  flex: 1 1 0;
  border: none;
  padding: ${({ theme, size }) =>
    `${theme.button.paddingVertical * size}px ${
      theme.button.paddingHorizontal * size
    }px`};
  font-size: ${({ theme }) => theme.button.fontSize};
  text-align: center;
  min-width: 0;
  width: 0;
`;

interface Props {
  value: number;
  onChange: (value: number) => void;
  size?: number;
}

export const Quantity = ({ value, onChange, size = 1 }: Props) => {
  return (
    <QuantityRow size={size}>
      <QuantityButton size={size} onClick={() => onChange(value - 1)}>
        -
      </QuantityButton>
      <QuantityInput
        size={size}
        value={value}
        type="number"
        onChange={(e) => onChange(parseInt(e.target.value))}
      />
      <QuantityButton size={size} onClick={() => onChange(value + 1)}>
        +
      </QuantityButton>
    </QuantityRow>
  );
};
