import React, { FC } from 'react';
import { Section } from '@nimles/react-web-components';
import styled from '@emotion/styled';

export const OuterSection = styled(Section)`
  padding: 0;
  p {
    margin-bottom: 30px;
  }
  h1 {
    margin-bottom: 10px;
  }
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    text-align: left;
  }
`;

export const InnerSection = styled(Section)`
  background-position-x: 0%;
  text-align: center;
  color: #fff;
  p {
    margin-bottom: 30px;
  }
  h1 {
    margin-bottom: 10px;
  }
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    text-align: left;
  }
`;

export const BannerSection: FC<any> = ({
  children,
  minHeight,
  padding,
  ...props
}) => (
  <OuterSection {...props}>
    <InnerSection
      minHeight={minHeight ?? '80vh'}
      padding={padding ?? '80px 0 0'}
    >
      {children}
    </InnerSection>
  </OuterSection>
);
