import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { CartContext } from '../context/cartContext';
import { SvgCart } from '../../svg/SvgCart';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { CartModel, PricelistModel } from '@nimles/models';
import { State } from '@nimles/react-redux';
import { useCurrency } from '../../../hooks/currencyHook';
import { Price } from '../../price/Price';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PricelistPublicModel } from '@nimles/models/lib/public/ecommerce';

const Button = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: relative;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 6px !important;

  svg {
    height: 34px;
    width: 34px;
    margin: -5px 10px -5px 0;
  }
`;

const Quantity = styled.div`
  position: absolute;
  top: 0;
  left: 30px;
  background: ${({ theme }) => theme.colors.primary.color};
  color: ${({ theme }) => theme.colors.primary.onColor};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
`;

export const CartButton = () => {
  const { t } = useTranslation();
  const { selected: cart } = useSelector<RootState, State<CartModel>>(
    ({ carts }) => carts
  );

  const isGross = useSelector<RootState>(({ grossPrice }) => grossPrice);

  const pricelists = useSelector<RootState, PricelistPublicModel[]>(
    ({ publicPricelists }) => publicPricelists.values
  );
  const { currency } = useCurrency();

  const pricelist = pricelists.find((p) => p.currency == currency);
  const products = useSelector<RootState, ProductModel[]>(
    ({ publicProducts }) => publicProducts.values
  );
  let quantity = cart?.items?.reduce((sum, item) => sum + item.quantity, 0);

  const totalPrice = useMemo(() => {
    const pricelist = pricelists.find((p) => p.currency === currency);
    return (
      cart?.items?.reduce((sum, { productId, variantId, quantity }) => {
        const product = products.find(({ id }) => id === productId);
        const variant = product?.variants.find(
          (variant) => variant.id === variantId
        );

        const productPrice = pricelist?.prices?.find(
          (p) => p.productId === product?.id && p?.variantId === variant.id
        );

        const netPrice =
          productPrice?.netPrice ?? variant?.netPrice ?? product?.netPrice;

        const grossPrice =
          productPrice?.grossPrice ??
          variant?.grossPrice ??
          product?.grossPrice;

        const discountNetPrice = productPrice?.discountNetPrice;

        const discountGrossPrice = productPrice?.discountGrossPrice;

        if (isGross === true && !discountGrossPrice) {
          return grossPrice ? sum + quantity * grossPrice : sum;
        }
        if (isGross === true && discountGrossPrice) {
          return discountGrossPrice ? sum + quantity * discountGrossPrice : sum;
        }
        if (isGross === false && discountNetPrice) {
          return discountNetPrice ? sum + quantity * discountNetPrice : sum;
        } else {
          return netPrice ? sum + quantity * netPrice : sum;
        }
      }, 0) ?? 0
    );
  }, [cart, pricelists, products, isGross]);

  return (
    <CartContext.Consumer>
      {({ isCartOpen, setCartOpen }) => (
        <Button
          aria-label={t('prop.shoppingcart')}
          onClick={() => setCartOpen((prev) => !prev)}
        >
          <SvgCart />
          <Quantity>{quantity}</Quantity>
          {isCartOpen ? <Price value={totalPrice} /> : null}
        </Button>
      )}
    </CartContext.Consumer>
  );
};
