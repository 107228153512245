import React from 'react';
import styled from '@emotion/styled';
import { PostModel } from '@nimles/models';
import { Row, Column } from '@nimles/react-web-components';
import { BlogPost } from './BlogPost';
import { compareBy } from '../../../utils';

const StyledRow = styled(Row)<{ singleRow: boolean }>`
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    overflow: hidden;
    flex-wrap: ${({ singleRow }) => (singleRow ? 'nowrap' : 'wrap')};
  }
`;

interface Props {
  posts: PostModel[];
  orderBy?: string;
  singleRow?: boolean;
}

export const BlogPosts = ({ posts, orderBy, singleRow }: Props) => {
  if (orderBy) {
    posts = posts.sort(compareBy(orderBy));
  }

  return (
    <StyledRow singleRow={singleRow}>
      {posts.map((post) => (
        <Column key={post.id} xs={100} md={50} xl={40} xxl={40}>
          <BlogPost post={post} />
        </Column>
      ))}
    </StyledRow>
  );
};
