export interface Country {
  name: string;
  language: string;
  currency: string;
}

export const countries: Country[] = [
  { name: 'Sverige', language: 'sv', currency: 'sek' },
  { name: 'Espana', language: 'es', currency: 'eur' },
  { name: 'Denmark', language: 'dk', currency: 'eur' },
  { name: 'UK', language: 'en', currency: 'gbp' },
];
