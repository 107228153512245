import React from 'react';
import styled from '@emotion/styled';
import {
  Column,
  Container,
  Row,
  Header,
  HeaderMenu,
} from '@nimles/react-web-components';
import logo from '../../images/gardsbutiker/logo.svg';
import { Props } from './types';
import { Link } from 'gatsby-plugin-react-i18next';

const Brand = styled(Link)`
  color: ${({ theme }) => theme.header.color};
  text-decoration: none;
  padding: 10px 0;
  font-family: 'Balsamiq Sans', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
`;

const HeaderItem = styled.li`
  margin: 0;
`;

const HeaderLink = styled(Link)`
  color: ${({ theme }) => theme.header.color};
  text-decoration: none;
  font-family: sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 20px 6px;
  display: block;
  border-left: 4px solid transparent;
  transition: all 300ms;
  margin-bottom: 10px;

  &:hover {
    border-left-color: rgba(0, 0, 0, 0.5);
  }

  &.active {
    border-left-color: ${({ theme }) => theme.colors.primary.color};
  }

  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    border-left: 0;
    border-bottom: 4px solid transparent;
    margin-bottom: 0;
    margin-right: 10px;

    &:hover {
      border-bottom-color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      border-bottom-color: ${({ theme }) => theme.colors.primary.color};
    }
  }
`;

const Logo = styled.img`
  margin-right: 10px;
  height: 44px;
`;

const Name = styled.h3`
  font-size: 22px;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    font-size: 30px;
  }
`;

interface LinkItem {
  to: string;
  name: string;
}

const items = [
  {
    name: 'Karta',
    to: '/karta',
  },
  {
    name: 'Kontakt',
    to: '/kontakt',
  },
  {
    name: 'Om oss',
    to: '/om-oss',
  },
];

export const HeaderLayout: React.FC<Props> = () => {
  return (
    <Header alwaysOpen>
      <Container>
        <Row align="center">
          <Column>
            <Brand to="/">
              <Logo src={logo} alt="Gårdsbutiker" />
              <Name>Gårdsbutiker</Name>
            </Brand>
          </Column>
          <Column flex></Column>
          <Column>
            <HeaderMenu>
              {items &&
                items.map((item, index) => (
                  <HeaderItem key={index}>
                    <HeaderLink to={item.to} activeClassName="active">
                      {item.name}
                    </HeaderLink>
                  </HeaderItem>
                ))}
            </HeaderMenu>
          </Column>
        </Row>
      </Container>
    </Header>
  );
};
