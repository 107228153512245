import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { PricelistModel, ProductModel } from '@nimles/models';
import { Row, Column } from '@nimles/react-web-components';
import { compareBy } from '../../../utils';
import { ProductCard } from '../card/ProductCard';
import { useSelector } from 'react-redux';
import { GeoState, RootState } from '../../../redux/types';

const StyledRow = styled(Row)<{ singleRow: boolean }>`
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    overflow: hidden;
    flex-wrap: ${({ singleRow }) => (singleRow ? 'nowrap' : 'wrap')};
  }
`;

interface Props {
  products: ProductModel[];
  pricelists: PricelistModel[];
  orderBy?: string;
  start?: number;
  limit?: number;
  onlyWithBanner?: boolean;
  singleRow?: boolean;
  css?: any;
}

export const ProductGrid = ({
  products,
  pricelists,
  css,
  orderBy,
  start = 0,
  limit,
  onlyWithBanner,
  singleRow,
}: Props) => {
  const geo = useSelector<RootState, GeoState>(({ geo }) => geo);

  const peparedProducts = useMemo(() => {
    const sortedProducts = products
      .map((product) => ({
        ...product,
        popularity: product.description?.length || 0,
      }))
      .sort(compareBy(orderBy || 'name', null, orderBy === 'popularity'));

    return limit ? sortedProducts.slice(start, limit) : sortedProducts;
  }, [geo.userPosition, products, orderBy, start, limit]);

  return (
    <StyledRow singleRow={singleRow}>
      {peparedProducts
        .reduce(
          (result, product) =>
            product.variants?.length
              ? [
                  ...result,
                  ...product.variants.map((variant) => ({ variant, product })),
                ]
              : result,
          []
        )
        .map(({ variant, product }) => (
          <Column key={variant.id} xs={100} md={50} xl={33.333} xxl={25}>
            <ProductCard
              product={product}
              variant={variant}
              pricelists={pricelists}
            />
          </Column>
        ))}
    </StyledRow>
  );
};
