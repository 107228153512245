import styled from '@emotion/styled';

export const SocialLink = styled.a<{ background?: string }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.background.onColor};
  margin: 20px 0;
`;
