import { I18nextContext } from 'gatsby-plugin-react-i18next';
import { useContext, useState } from 'react';
import { countries } from '../components/country/countries';
export const useCurrency = () => {
  const { language } = useContext(I18nextContext);

  return {
    currency: countries.find((country) => country.language === language)
      .currency,
  };
};
