import React, { useState, FC } from 'react';
import styled from '@emotion/styled';
import { Input, Row, Column } from '@nimles/react-web-components';
import { rgba } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarker,
  faSpinner,
  faSearch,
  faTimes,
  faCompass,
} from '@fortawesome/free-solid-svg-icons';
import { getImageUrl, compareBy } from '../utils';
import { requestUserPosition } from '../redux/geo';
import { useDispatch } from 'react-redux';
import { CategoryModel, OrganizationModel } from '@nimles/models';

const SearchContainer = styled.div<{ isOpen: boolean }>`
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'relative')};
  z-index: ${({ isOpen }) => (isOpen ? 999 : 'unset')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    position: relative;
  }
`;

const SearchInputContainer = styled.div<{ isFocused: boolean }>`
  position: relative;
  z-index: ${({ isFocused }) => (isFocused ? 999 : 1)};
  display: flex;
  background-color: ${({ theme }) => theme.colors.surface.color};
  align-items: center;
  padding-left: 20px;
  border-radius: ${({ isFocused }) => (isFocused ? 0 : '4px')};
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    border: 1px solid ${({ theme }) => theme.input.borderColor};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-color: ${({ theme, isFocused }) =>
      isFocused ? theme.colors.surface.color : theme.input.borderColor};
  }
  color: ${({ theme }) => theme.colors.surface.onColor};
`;

const SearchForm = styled.form`
  display: flex;
  flex: 1;
`;

const SearchInput = styled(Input)`
  position: relative;
  border: 0;
  outline: 0;
  padding: 10px;
  font-size: 18px;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    padding: 20px 10px;
    font-size: 20px;
  }
`;

const CloseButton = styled.button`
  border: 0;
  outline: 0;
  padding: 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    display: none;
  }
`;

const SearchBackground = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => rgba(theme.colors.surface.onColor, 0.5)};
`;

const SearchResult = styled.div<{ isOpen: boolean }>`
  flex: 1;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: relative;
  background: ${({ theme }) => theme.colors.surface.color};
  padding: 10px 15px;
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    position: absolute;
    border: 1px solid ${({ theme }) => theme.input.borderColor};
    border-top: 0;
    top: 100%;
    bottom: auto;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const NearMe = styled.button`
  cursor: pointer;
  border: 0;
  background: transparent;
  outline: 0;
  padding: 2px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.surface.onColor};

  svg {
    margin-right: 10px;
  }
`;

const SearchCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px 10px;
`;

const SearchOrganizations = styled.div`
  display: flex;
`;

const SearchOrganization = styled.button`
  cursor: pointer;
  border: 0;
  background: transparent;
  outline: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 100px;
  height: 60px;
  object-fit: cover;
`;

const SearchCategory = styled.button<{ isSelected: boolean }>`
  cursor: pointer;
  border: 0;
  background: transparent;
  outline: 0;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.colors.surface.onColor};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary.onColor : theme.colors.surface.onColor};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary.color : 'transparent'};
  padding: 2px 8px;
  margin: 4px;
  border-radius: 4px;

  svg {
    margin-left: 6px;
  }
`;

const SearchRegions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px 10px;
`;

const SearchRegion = styled.button`
  cursor: pointer;
  border: 0;
  background: transparent;
  outline: 0;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.colors.surface.onColor};
  padding: 2px 8px;
  margin: 4px;
  border-radius: 4px;
`;

const SearchTitle = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => rgba(theme.colors.surface.onColor, 0.5)};
`;

interface Props {
  categories?: CategoryModel[];
  selectedCategories?: CategoryModel[];
  regions?: string[];
  organizations?: OrganizationModel[];
  query?: string;
  onQuery?: (query: string) => void;
  inline?: boolean;
  onSubmit: (values: {
    query?: string;
    position?: {
      latitude: number;
      longitude: number;
    };
    region?: string;
    category?: CategoryModel;
    categories?: CategoryModel[];
    organization?: OrganizationModel;
  }) => void;
}

export const Search: FC<Props> = ({
  categories,
  selectedCategories,
  regions,
  organizations,
  onSubmit,
  query,
  onQuery,
  inline,
}) => {
  const dispatch = useDispatch();
  const [queryValue, setQueryValue] = useState<string>('');
  const [isFocused, setFocused] = useState(false);
  const [isLoadingPosition, setLoadingPosition] = useState(false);

  const queryFilter = onQuery ? query : queryValue;
  const filteredCategories = categories?.filter(({ name }) =>
    queryFilter ? name.toLowerCase().includes(queryFilter.toLowerCase()) : true
  );
  const filteredRegions = regions?.filter((region: string) =>
    queryFilter
      ? region.toLowerCase().includes(queryFilter.toLowerCase())
      : true
  );
  const filteredOrganizations = (queryFilter
    ? organizations?.filter(({ name }) =>
        name.toLowerCase().includes(queryFilter.toLowerCase())
      )
    : organizations
  ).sort(compareBy('name'));

  const requestPostion = async () => {
    try {
      setLoadingPosition(true);
      const position: any = await dispatch(requestUserPosition());
      setLoadingPosition(false);
      if (position) {
        setFocused(false);
        onSubmit({ position });
      }
    } catch (error) {
      setLoadingPosition(false);
      console.warn(error);
      alert('Du behöver godkänna att vi får använda din plats');
    }
  };

  const handleChange = (e) =>
    onQuery ? onQuery(e.target.value) : setQueryValue(e.target.value);

  return (
    <SearchContainer isOpen={isFocused}>
      <SearchBackground isOpen={isFocused} onClick={() => setFocused(false)} />
      <SearchInputContainer isFocused={isFocused}>
        <FontAwesomeIcon
          onClick={() => setFocused(true)}
          icon={isLoadingPosition ? faCompass : faSearch}
          spin={isLoadingPosition}
        />
        <SearchForm
          onSubmit={(e) => {
            e.preventDefault();
            setFocused(false);
            onSubmit({ query: queryValue });
          }}
        >
          <SearchInput
            placeholder={
              isLoadingPosition
                ? 'Searching for your position'
                : 'Search services'
            }
            value={(onQuery ? query : queryValue) ?? ''}
            onChange={handleChange}
            onFocus={() => setFocused(true)}
          />
        </SearchForm>
        {isFocused ? (
          <CloseButton onClick={() => setFocused(false)}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </CloseButton>
        ) : null}
      </SearchInputContainer>
      <SearchResult isOpen={isFocused}>
        {filteredRegions?.length ? (
          <SearchTitle>Var vill du se tjänster?</SearchTitle>
        ) : null}
        {!queryValue ? (
          <Row justify="space-between">
            <Column>
              <NearMe
                onClick={(e) => {
                  e.stopPropagation();
                  requestPostion();
                }}
              >
                <FontAwesomeIcon icon={faMapMarker} />I närheten av mig
              </NearMe>
            </Column>
          </Row>
        ) : null}
        <SearchRegions>
          {filteredRegions?.map((region: string) => (
            <SearchRegion
              key={region}
              onClick={(e) => {
                e.stopPropagation();
                setFocused(false);
                onSubmit({ region: region.toLowerCase() });
              }}
            >
              {region}
            </SearchRegion>
          ))}
        </SearchRegions>
        {filteredCategories?.length ? (
          <SearchTitle>Vad är du intresserad av?</SearchTitle>
        ) : null}
        <SearchCategories>
          {filteredCategories
            ?.sort((a, b) => (b.name < a.name ? 1 : b.name > a.name ? -1 : 0))
            .map((category) => {
              const isSelected = selectedCategories?.some(
                ({ id }) => category.id === id
              );
              return (
                <SearchCategory
                  isSelected={isSelected}
                  key={category.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (selectedCategories) {
                      if (
                        selectedCategories?.some(({ id }) => category.id === id)
                      ) {
                        onSubmit({
                          categories: selectedCategories.filter(
                            ({ id }) => category.id !== id
                          ),
                        });
                      } else {
                        onSubmit({
                          categories: [...selectedCategories, category],
                        });
                      }
                    } else {
                      setFocused(false);
                      onSubmit({ category });
                    }
                  }}
                >
                  {category.name}
                  {isSelected ? <FontAwesomeIcon icon={faTimes} /> : null}
                </SearchCategory>
              );
            })}
        </SearchCategories>
        {filteredOrganizations?.slice(0, 10).map((organization) => (
          <SearchOrganization
            key={organization.id}
            onClick={(e) => {
              e.stopPropagation();
              setFocused(false);
              onSubmit({ organization });
            }}
          >
            <Row>
              <Column>
                <Image
                  src={
                    organization.bannerId
                      ? getImageUrl(organization.bannerId) + '?width=480'
                      : null
                  }
                />
              </Column>
              <Column flex>{organization.name}</Column>
            </Row>
          </SearchOrganization>
        ))}
      </SearchResult>
    </SearchContainer>
  );
};
