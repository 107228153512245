import React, { useContext, useMemo } from 'react';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import { useCurrency } from '../../hooks/currencyHook';
import { Column, Row } from '@nimles/react-web-components';
import styled from '@emotion/styled';

interface Props {
  value: number;
  discountValue?: number;
  currency?: string;
}

const OldValue = styled.div`
  text-decoration: line-through;
  opacity: 0.6;
  font-size: 0.8em;
  letter-spacing: 0;
  margin-right: 10px;
`;
const DiscountValue = styled.div`
  color: red;
  font-weight: 600;
`;
const Value = styled.div`
  font-weight: 600;
`;

const StyledColumn = styled(Column)`
  padding: 0;
`;

export const Price = ({ value, discountValue, currency, ...props }: Props) => {
  const { language } = useContext(I18nextContext);
  const { currency: defaultCurrency } = useCurrency();

  currency = currency || defaultCurrency;

  const numberFormatter = useMemo(
    () =>
      language &&
      currency &&
      Intl.NumberFormat(language, {
        style: 'currency',
        currency,
      }),
    [language, currency]
  );

  const hasDiscount = !!discountValue;

  const oldValue = hasDiscount ? value : null;

  const currentValue = hasDiscount ? discountValue : value;

  return (
    <Row {...props} alignItems="center" justify="center" margin="0">
      {!oldValue ? null : (
        <OldValue>{numberFormatter?.format(oldValue)}</OldValue>
      )}
      {!currentValue ? null : hasDiscount ? (
        <DiscountValue>{numberFormatter?.format(currentValue)}</DiscountValue>
      ) : (
        <Value>{numberFormatter?.format(currentValue)}</Value>
      )}
    </Row>
  );
};
