import { CartItemModel, PricelistModel, ProductModel } from '@nimles/models';
import { loadPublicProduct } from '@nimles/react-redux';
import { Column, ListItem, Row } from '@nimles/react-web-components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { useCart } from '../../hooks/cartHook';
import { getImageUrl } from '../../utils';
import { Price } from '../price/Price';
import { Quantity } from '../quantity/Quantity';
import { useCurrency } from '../../hooks/currencyHook';
import styled from '@emotion/styled';
import { PricelistPublicModel } from '@nimles/models/lib/public/ecommerce';

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const CartItem = ({
  item: { productId, variantId, quantity },
}: {
  item: CartItemModel;
}) => {
  const dispatch = useDispatch();
  const { updateItem } = useCart();
  const products = useSelector<RootState, ProductModel[]>(
    ({ publicProducts }) => publicProducts.values
  );
  const pricelists = useSelector<RootState, PricelistPublicModel[]>(
    ({ publicPricelists }) => publicPricelists.values
  );
  const { currency } = useCurrency();

  const pricelist = pricelists.find((p) => p.currency === currency);
  const product = products.find(({ id }) => id === productId);
  const variant = product?.variants.find((variant) => variant.id === variantId);

  const isGross = useSelector<RootState>(({ grossPrice }) => grossPrice);

  useEffect(() => {
    if (productId && !product) {
      dispatch(loadPublicProduct(productId));
    }
  }, [productId, product?.id]);

  const handleQuantityChange = (value) => {
    updateItem(productId, variantId, value);
  };

  const netPrice =
    pricelist?.prices?.find(
      (p) => p.productId === productId && p.variantId === variantId
    )?.netPrice ??
    variant?.netPrice ??
    product?.netPrice;

  const grossPrice =
    pricelist?.prices?.find(
      (p) => p.productId === product?.id && p.variantId === variant?.id
    )?.grossPrice ??
    variant?.grossPrice ??
    product?.grossPrice;

  const discountNetPrice = pricelist?.prices?.find(
    (p) => p.productId === product?.id && p?.variantId === variant.id
  )?.discountNetPrice;

  const discountGrossPrice = pricelist?.prices?.find(
    (p) => p.productId === product?.id && p?.variantId === variant.id
  )?.discountGrossPrice;

  return (
    <ListItem>
      {product ? (
        <div>
          <Row align="center" margin="0 -15px 10px">
            <Column>
              {product.imageIds?.length ? (
                <Image src={getImageUrl(product.imageIds[0])} />
              ) : null}
            </Column>
            <Column flex>
              <div>
                <strong>{variant?.name || product?.name}</strong>
              </div>
            </Column>
          </Row>
          <Row alignItems="center">
            <Column xs={50}>
              <Quantity
                size={0.6}
                value={quantity}
                onChange={handleQuantityChange}
              />
            </Column>
            <Column xs={50} alignItems="flex-end">
              <Price
                value={
                  isGross === true ? grossPrice * quantity : netPrice * quantity
                }
                discountValue={isGross ? discountGrossPrice : discountNetPrice}
                currency={currency}
              />
            </Column>
          </Row>
        </div>
      ) : null}
    </ListItem>
  );
};
