import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardImage,
  Column,
  Row,
  CardHeader,
} from '@nimles/react-web-components';
import { Link } from 'gatsby-plugin-react-i18next';
import styled from '@emotion/styled';
import { getImageUrl } from '../../../utils';
import { PostModel, ProductModel } from '@nimles/models';
import moment from 'moment';

const ProductLink = styled(Link)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 800ms;

  & img {
    transition: all 800ms;
  }

  & section {
    border: 1px solid #efefef;
    overflow: hidden;
  }

  &:hover img {
    transform: scale(1.05);
    transition: all 300ms;
  }

  &:hover section {
    border-color: ${({ theme }) => theme.colors.primary.color};
    transition: all 100ms;
  }
`;

const Description = styled.div`
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  max-height: 85px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.surface.onColor};

  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      transparent 100%
    );
    display: block;
    position: absolute;
    height: 10px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
  font-family: 'Lato', sans-serif;
  flex: 1 0 auto;
`;

interface Props {
  post: PostModel;
}

const StyledRow = styled.div`
  flex-direction: row;
`;

export const BlogPost = ({ post }: Props) => {
  const element = post.elements.map((element) => element);

  return (
    <Link to={`/${post.uniqueName}-po`}>
      <Card>
        <CardHeader>
          <CardTitle>{post.title}</CardTitle>
          <span>{moment(post.publishedDate).format('MMMM D, YYYY')}</span>
        </CardHeader>
        <CardBody>
          <Row>
            <Column xs={100} md={70} lg={70} xl={70}>
              <Description>{post.summary}</Description>
            </Column>
            <Column flex>
              <CardImage
                src={getImageUrl(
                  post.imageId?.length ? post.imageId : null,
                  480
                )}
                alt=""
                ratio={1.5}
                fit="contain"
              />
            </Column>
          </Row>
        </CardBody>
      </Card>
    </Link>
  );
};
