import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { OrganizationModel } from '@nimles/models';
import { Row, Column, Button } from '@nimles/react-web-components';
import { compareBy, distance } from '../../../utils';
import { OrganizationCard } from '../card/OrganizationCard';
import { useDispatch, useSelector } from 'react-redux';
import { GeoState, RootState } from '../../../redux/types';
import { requestUserPosition } from '../../../redux/geo';

const Placeholder = styled.div`
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.default.padding * 2}px;
  text-align: center;
`;

const StyledRow = styled(Row)<{ singleRow: boolean }>`
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    overflow: hidden;
    flex-wrap: ${({ singleRow }) => (singleRow ? 'nowrap' : 'wrap')};
  }
`;

interface Props {
  organizations: OrganizationModel[];
  orderBy?: string;
  numberOfItems?: number;
  onlyWithBanner?: boolean;
  singleRow?: boolean;
  css?: any;
}

export const OrganizationGrid = ({
  organizations,
  css,
  orderBy,
  numberOfItems,
  onlyWithBanner,
  singleRow,
}: Props) => {
  const dispatch = useDispatch();
  const geo = useSelector<RootState, GeoState>(({ geo }) => geo);

  const requestPosition = () => dispatch(requestUserPosition());

  const peparedOrganizations = useMemo(() => {
    const orgs = organizations
      .filter((organization) => !onlyWithBanner || organization.bannerId)
      .map((organization) => ({
        ...organization,
        popularity: organization.description?.length || 0,
        distance:
          geo.userPosition && organization.location
            ? distance(geo.userPosition, organization.location, 'K')
            : Number.MAX_SAFE_INTEGER,
      }))
      .sort(compareBy(orderBy || 'name', null, orderBy === 'popularity'));

    return numberOfItems ? orgs.slice(0, numberOfItems) : orgs;
  }, [geo.userPosition, organizations, orderBy, numberOfItems]);

  return (
    <StyledRow singleRow={singleRow}>
      {orderBy !== 'distance' || geo.userPosition ? (
        peparedOrganizations.map((organization) => (
          <Column key={organization.id} xs={100} md={50} xl={33.333} xxl={25}>
            <OrganizationCard organization={organization} />
          </Column>
        ))
      ) : (
        <Placeholder>
          <p>
            Din aktuella position behövs för att kunna hitta gårdsbutikerna
            närmast dig.
          </p>
          <Button primary raised onClick={requestPosition} margin="10px">
            Hämta min position
          </Button>
        </Placeholder>
      )}
    </StyledRow>
  );
};
