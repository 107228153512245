import { Global, css } from '@emotion/react';
import React from 'react';
import { darken } from 'polished';
import { useTheme } from '@emotion/react';

export const GlobalStyles = () => {
  const theme = useTheme();

  theme.colors.background.color = '#f5f2ec';
  theme.colors.primary.color = '#008c00';
  theme.colors.secondary.color = '#abbe99';
  theme.colors.secondary.onColor = '#000';
  theme.colors.default.color = 'rgba(67, 54, 47, 0.5)';
  theme.header.background = '#6f53bf';
  theme.header.color = '#fff';
  theme.footer.background = '#111';
  theme.footer.secondaryBackground = 'rgba(0, 0, 0, 1)';
  theme.footer.color = '#fff';
  theme.default.padding = 15;
  theme.card.marginBottom = 30;
  theme.card.paddingBottom = 15;
  theme.card.borderRadius = 0;
  theme.card.boxShadow = 'none';
  theme.default.borderRadius = 0;
  theme.input.color = '#000';
  theme.banner.primary.color = '#1A2238';
  theme.banner.primary.onColor = '#fff';
  theme.banner.secondary.color = '#13192c';
  theme.section.paddingTop = 30;
  theme.section.paddingBottom = 30;

  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
        }
        html {
          background-color: ${theme.colors.background.color};
        }

        body {
          margin: 0;
          padding: 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: ${theme.colors.background.onColor};
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: 0.6px;
          position: relative;
          font-family: 'Open Sans', sans-serif;
          z-index: 1;
          overflow-x: hidden;
          @media (min-width: ${theme.thresholds.md}px) {
            font-size: 20px;
          }
        }

        .main {
          min-height: 80vh;
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
        }

        fieldset {
          border: none;
          padding: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: lighter;
          line-height: normal;
        }

        h1 {
          letter-spacing: 3px;

          font-size: 30px;
          margin-top: 40px;
          margin-bottom: 40px;
        }

        h2 {
          font-size: 24px;
          margin-top: 17px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 24px;
          margin-top: 17px;
          margin-bottom: 10px;
        }

        h4 {
          margin-top: 10px;
          margin-bottom: 5px;
        }

        p {
          margin-top: 5px;
          margin-bottom: 10px;
        }

        @media (min-width: 576px) {
          h1 {
            font-size: 45px;
          }

          h2 {
            font-size: 30px;
          }

          h3 {
            font-size: 25px;
          }
        }

        a {
          color: ${theme.colors.primary.color};
          text-decoration: none;
          cursor: pointer;
        }

        legend,
        label {
          font-weight: 700;
          margin-top: 10px;
          color: ${darken(0.2, theme.colors.primary.color)};
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: 26px;
          padding: 0;
        }

        button,
        input,
        textarea {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          padding: ${theme.default.padding}px ${theme.default.padding * 2}px;
        }

        pre {
          white-space: normal;
        }
      `}
    />
  );
};
