// Context is made up of two things
// Provider - Single as close to top level as possible

import React, { createContext, FC, useState } from 'react';

interface ProviderProps {
  cartOpen?: boolean;
}

// Consumer - Multiple have multiple consumers
export const CartContext = createContext(undefined);

export const CartProvider: FC<ProviderProps> = ({
  children,
  cartOpen = false,
}) => {
  const [isCartOpen, setCartOpen] = useState(cartOpen);

  return (
    <CartContext.Provider
      value={{
        isCartOpen,
        setCartOpen,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
