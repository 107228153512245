import React from 'react';
import { useTheme } from '@emotion/react';
import { Theme } from '@nimles/react-web-components';

export const SvgUser = () => {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={48}
      height={48}
      className="symbol symbol-fill"
      data-reactid=".0.$625.1"
    >
      <use xlinkHref="#icon-651" data-reactid=".0.$625.1.0">
        <defs>
          <path
            id="icon-651"
            d="M42.004,33.734c-5.179-1.04-8.953-3.319-10.074-4.056c-0.027-0.493-0.032-1.412-0.019-2.258 c1.13-1.582,1.745-3.694,1.982-4.654c0.678-0.354,1.616-1.108,2.256-3.354c0.582-2.007,0.09-3.17-0.354-3.756 c0.14-1.793,0.311-8.033-3.659-11.12C29.97,2.854,27.57,2,25.003,2c-3.6,0-6.399,1.689-7.158,2.197 c-3.348,0.698-4.967,4.416-5.055,6.22c-0.046,1.151,0.125,4.201,0.189,5.268c-0.462,0.748-0.828,2.155-0.2,4.165 c0.593,1.938,1.467,2.699,2.152,2.988c0.234,0.925,0.546,1.64,0.827,2.284c0.222,0.509,0.413,0.948,0.523,1.396 c0.193,0.809,0.128,2.515,0.067,3.402c-1.975,2.167-7.721,3.477-9.762,3.798C3.758,33.928,2,38.251,2,45v1h44v-1 C46,39.942,44.95,34.327,42.004,33.734z M4.015,44c0.165-5.521,1.682-8.211,2.783-8.295c0.085-0.012,8.646-1.329,11.315-4.776 l0.18-0.233l0.025-0.293c0.026-0.298,0.243-2.953-0.092-4.355c-0.152-0.62-0.386-1.157-0.634-1.725 c-0.316-0.724-0.643-1.473-0.838-2.506l-0.177-0.936l-0.844,0.118c-0.114-0.046-0.608-0.326-1.042-1.739 c-0.553-1.771,0.001-2.541-0.004-2.541l0.342-0.322l-0.03-0.469c-0.002-0.042-0.261-4.157-0.21-5.421 c0.039-0.809,1.042-3.961,3.611-4.378l0.238-0.039l0.195-0.144C18.858,5.926,21.508,4,25.003,4c2.112,0,4.1,0.712,5.905,2.116 c3.664,2.85,2.864,9.706,2.855,9.774l-0.128,0.582l0.521,0.341c0.005,0.005,0.522,0.488,0.07,2.047 c-0.519,1.821-1.099,2.071-1.601,2.288l-0.478,0.21l-0.102,0.511c-0.006,0.029-0.591,2.908-1.916,4.592l-0.206,0.262l-0.008,0.334 c-0.044,1.934-0.001,3.113,0.127,3.505l0.1,0.306l0.26,0.19c0.183,0.134,4.56,3.302,11.205,4.637 c0.942,0.189,2.228,3.161,2.376,8.305H4.015z"
          />
        </defs>
      </use>
    </svg>
  );
};
