import React from 'react';
import styled from '@emotion/styled';
import { ProductGroupModel } from '@nimles/models';
import { Row, Column } from '@nimles/react-web-components';
import { getImageUrl } from '../../../utils';
import { Link } from 'gatsby-plugin-react-i18next';

const ProductGroupLogo = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const ProductGroupRow = styled(Row)`
  margin: 16px -8px;
`;
const ProductGroupColumn = styled(Column)`
  padding: 0 8px;
`;

const ProductGroupLink = styled(Link)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: ${({ theme }) => theme.colors.surface.onColor};
  border: 1px solid ${({ theme }) => theme.input.borderColor};

  box-shadow: 0 3px 8px rgb(0 0 0 / 8%);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
  border-radius: ${({ theme }) => theme.default.borderRadius}px;
  padding: 8px;
  text-align: center;
`;

const ProductGroupIcon = styled.img`
  width: 100%;
  margin: 10px 30% 15px;
  max-width: 40px;
`;

const ProductGroupText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
  font-size: 12px;
  letter-spacing: 0;

  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    font-size: 14px;
  }
`;

interface Props {
  productGroups: ProductGroupModel[];
}

export const ProductGroupGrid = ({ productGroups }: Props) => {
  return (
    <ProductGroupRow wrap="wrap">
      {productGroups
        ?.filter(({ parentId }) => !parentId)
        .map((productgroup) => ({
          ...productgroup,
          popularity: productgroup.description?.length || 0,
        }))
        .sort((a, b) => (b.name < a.name ? 1 : b.name > a.name ? -1 : 0))
        .map(({ name, uniqueName, logoId }, index) => (
          <ProductGroupColumn key={index} xs={50} md={33.33}>
            <ProductGroupLink to={`/${uniqueName}-pg`}>
              <ProductGroupIcon src={getImageUrl(logoId)} />
              <ProductGroupText>{name}</ProductGroupText>
            </ProductGroupLink>
          </ProductGroupColumn>
        ))}
    </ProductGroupRow>
  );
};
