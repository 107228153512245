import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { relative } from 'path';

const ResponsiveVideo = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-bottom: 30px;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const YoutubeEmbed = ({ embedId }) => (
  <ResponsiveVideo>
    <iframe
      width="800"
      height="520"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </ResponsiveVideo>
);

export default YoutubeEmbed;
