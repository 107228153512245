import * as React from 'react';
import { Container, Column, Row, Button } from '@nimles/react-web-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import logo from '../../images/gardsbutiker/logo.svg';
import { SocialLink } from '../../components/social/SocialLink';
import { socialArray } from '../../utils';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Link } from 'gatsby-plugin-react-i18next';

const FooterContainer = styled.footer`
  margin-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
  color: ${({ theme }) => theme.footer.color};
  background-color: ${({ theme }) => theme.footer.background};
`;

const LinkColumn = styled(Column)`
  align-items: center;
`;

const CtaColumn = styled(Column)`
  text-align: center;
`;

const CtaText = styled.p`
  max-width: 400px;
  margin-bottom: 40px;
`;

export const IconColumn = styled(Column)`
  text-align: center;
  position: relative;
  padding: 20px 20px 50px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -50px;
    right: 0;
    left: -10000px;
    bottom: 0;
    background-color: ${({ theme }) => theme.footer.secondaryBackground};
  }
`;

const Brand = styled(Link)``;

const Logo = styled.img`
  position: relative;
  height: 30px;
`;

const Name = styled.h3``;

const FieldIcon = styled(FontAwesomeIcon)`
  font-weight: 400;
  font-size: 16px;
  margin-right: 10px;
`;

const Field = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 16px;
`;

const FooterList = styled.ul`
  list-style: none;
  padding: 30px 0;
  margin: 0;
  display: flex;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CompanyName = styled.h3`
  position: relative;
  font-family: 'Balsamiq Sans', sans-serif;
  color: ${({ theme }) => theme.footer.color};
`;

const FooterListItem = styled.li`
  padding: 0 15px;
`;

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.footer.color};
`;

const Email = styled.a`
  color: ${({ theme }) => theme.footer.color};
`;

const ContactButton = styled(Link)`
  background-color: whitesmoke;
  color: darkgreen;
  font-size: 24px;
  font-weight: 600;
  padding: 14px 20px;
  :hover {
    background-color: #fff;
  }
`;

const Copyright = styled.div`
  font-size: 16px;
`;

interface LinkItem {
  to: string;
  name: string;
}

interface Props {
  items?: LinkItem[];
}

const posts = [];

const items = [
  {
    name: 'Karta',
    to: '/karta',
  },
  {
    name: 'Kontakt',
    to: '/kontakt',
  },
  {
    name: 'Om oss',
    to: '/om-oss',
  },
];

export const FooterLayout: React.FC<Props> = () => {
  return (
    <FooterContainer>
      <Container>
        <Row wrap="wrap" justify="space-between">
          <IconColumn xs={100} md={25}>
            <Brand to="/">
              <Logo src={logo} alt="Gårdsbutiker" />
              <CompanyName>Gårdsbutiker</CompanyName>
            </Brand>
            <Field>
              <Email href="mailto: info@gardsbutiker.se">
                info@gardsbutiker.se
              </Email>
            </Field>
            <Row justify="center" wrap="wrap" margin="0 -10px">
              {socialArray.map((s) => (
                <Column key={s.name} padding="0 10px">
                  <SocialLink href={s.value} target="_blank">
                    <FontAwesomeIcon icon={s.icon} size="lg" color="white" />
                  </SocialLink>
                </Column>
              ))}
            </Row>
          </IconColumn>
          <CtaColumn xs={100} md={50} align="center">
            <h3>Vill du också synas?</h3>
            <CtaText>
              Kontakta oss om du vill att din gårdsbutik ska synas hos oss eller
              om du vill ändra uppgifterna om din gårdsbutik.
            </CtaText>
            <ContactButton to="/kontakt">Kontakta oss</ContactButton>
          </CtaColumn>
          <LinkColumn xs={100} md={25} justify="center">
            <FooterList>
              {items.map((item) => (
                <FooterListItem key={item.to}>
                  <FooterLink to={item.to}>{item.name}</FooterLink>
                </FooterListItem>
              ))}
            </FooterList>
          </LinkColumn>
        </Row>
      </Container>
      <Container padding="20px 15px">
        <Row justify="space-between">
          <Copyright>© 2020 Cloud Technologies</Copyright>
          <Copyright>Powered by Nimles</Copyright>
        </Row>
      </Container>
      {process.env.GATSBY_FACEBOOK_PAGE_ID &&
      process.env.GATSBY_FACEBOOK_PAGE_ID ? (
        <div>
          <MessengerCustomerChat
            pageId={process.env.GATSBY_FACEBOOK_PAGE_ID}
            appId={process.env.GATSBY_FACEBOOK_APP_ID}
            language="sv"
            loggedInGreeting="Välkommen till Gårdutiker.se"
            loggedOutGreeting="Välkommen till Gårdutiker.se"
            greetingDialogDisplay="show"
          />
        </div>
      ) : null}
    </FooterContainer>
  );
};
