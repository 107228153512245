import React from 'react';
import styled from '@emotion/styled';
import { useI18next, I18nextContext, Link } from 'gatsby-plugin-react-i18next';
import { useContext } from 'react';

const StyledLanguageSelector = styled.div`
  display: flex;
`;

const LanguageLink = styled(Link)<{ active: boolean }>`
  border-color: 'transparent';
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  padding: 10px 5px 6px !important;
  text-transform: uppercase;
`;

export const LanguageSelector = () => {
  const { languages, originalPath } = useI18next();
  const { language } = useContext(I18nextContext);

  return (
    <StyledLanguageSelector>
      {languages.map((lng) => (
        <LanguageLink
          key={lng}
          to={originalPath}
          language={lng}
          active={language === lng}
        >
          {lng}
        </LanguageLink>
      ))}
    </StyledLanguageSelector>
  );
};
