import React, { useEffect, useMemo } from 'react';
import { CartModel, ProductModel } from '@nimles/models';
import {
  loadPublicPricelists,
  loadPublicProduct,
  State,
} from '@nimles/react-redux';
import {
  Button,
  Column,
  List,
  Row,
  Sidenav,
  SidenavBody,
  SidenavFooter,
} from '@nimles/react-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { CartItem } from '../CartItem';
import { CartContext } from '../context/cartContext';
import { useCart } from '../../../hooks/cartHook';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { Price } from '../../price/Price';
import { PricelistPublicModel } from '@nimles/models/lib/public/ecommerce';
import { useCurrency } from '../../../hooks/currencyHook';
import { Quantity } from '../../quantity/Quantity';

const Checkout = Button.withComponent(Link);

export const CartMenu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { initCart: loadOrCreateCart } = useCart();
  const { currency } = useCurrency();
  const { selected: cart } = useSelector<RootState, State<CartModel>>(
    ({ carts }) => carts
  );
  const products = useSelector<RootState, ProductModel[]>(
    ({ publicProducts }) => publicProducts.values
  );
  const isGross = useSelector<RootState>(({ grossPrice }) => grossPrice);

  useEffect(() => {
    if (!cart) {
      loadOrCreateCart();
    }
  }, []);

  useEffect(() => {
    dispatch(loadPublicPricelists());
  }, []);

  const pricelists = useSelector<RootState, PricelistPublicModel[]>(
    ({ publicPricelists }) => publicPricelists.values
  );

  const totalPrice = useMemo(() => {
    const pricelist = pricelists.find((p) => p.currency === currency);
    return (
      cart?.items?.reduce((sum, { productId, variantId, quantity }) => {
        const product = products.find(({ id }) => id === productId);
        const variant = product?.variants.find(
          (variant) => variant.id === variantId
        );

        const netPrice =
          pricelist?.prices?.find(
            (p) => p.productId === productId && p.variantId === variantId
          )?.netPrice ??
          variant?.netPrice ??
          product?.netPrice;

        const grossPrice =
          pricelist?.prices?.find(
            (p) => p.productId === product?.id && p.variantId === variant?.id
          )?.grossPrice ??
          variant?.grossPrice ??
          product?.grossPrice;

        const discountNetPrice = pricelist?.prices?.find(
          (p) => p.productId === product?.id && p?.variantId === variant.id
        )?.discountNetPrice;

        const discountGrossPrice = pricelist?.prices?.find(
          (p) => p.productId === product?.id && p?.variantId === variant.id
        )?.discountGrossPrice;

        if (isGross === true && !discountGrossPrice) {
          return grossPrice ? sum + quantity * grossPrice : sum;
        }
        if (isGross === true && discountGrossPrice) {
          return discountGrossPrice ? sum + quantity * discountGrossPrice : sum;
        }
        if (isGross === false && discountNetPrice) {
          return discountNetPrice ? sum + quantity * discountNetPrice : sum;
        } else {
          return netPrice ? sum + quantity * netPrice : sum;
        }
      }, 0) ?? 0
    );
  }, [cart, pricelists, products, isGross]);

  return (
    <CartContext.Consumer>
      {({ isCartOpen, setCartOpen }) => (
        <Sidenav
          title={t('prop.shoppingCart')}
          open={isCartOpen}
          onClose={() => setCartOpen(false)}
        >
          <SidenavBody padding={0}>
            <List>
              {cart?.items?.map((item, index) => (
                <CartItem key={index} item={item} />
              ))}
            </List>
          </SidenavBody>
          <SidenavFooter>
            <Row justify="space-between">
              <Column>{t('prop.total')}</Column>
              <Column>
                <Price value={totalPrice} />
              </Column>
            </Row>
            <Row justify="center">
              <Column xs={100}>
                <Checkout
                  aria-label={t('action.checkout')}
                  primary
                  raised
                  to="/checkout"
                >
                  {t('action.checkout')}
                </Checkout>
              </Column>
            </Row>
          </SidenavFooter>
        </Sidenav>
      )}
    </CartContext.Consumer>
  );
};
